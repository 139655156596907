.article-list-page {
  padding: 20px;
  /* background-color: aliceblue; */
  margin: 6rem auto 2rem;
  width: 87%;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.article-item {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.article-item img {
  width: 70%;
  height: auto;
  display: block;
  margin: 15px auto 15px;
}

.article-item h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.article-item p {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

/* Additional CSS for handling dynamic gap filling */
.my-masonry-grid_column > .article-item:nth-child(odd) {
  margin-top: 30px; /* This creates a gap for odd-numbered items */
}
