.footer {
  /* background-color: #1a1a2e; */
  /* background-color: #3F5430; */
  /* background-color: rgb(19,26,34); */
  background-color: rgb(35,47,62);
  color: white;
  padding: 2rem 0;
  /*3F5430, 486832 , 354728 , 3F5530*/
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  padding: 0 2rem;
}

.footer-column {
  flex: 1;
  min-width: 200px;
  margin: 1rem 0;
}

.logo-column {
  text-align: left;
  margin: 1rem 2rem;
}

.footer-logo {
  width: 150px;
  border-radius: 50%;
}

.footer-title {
  font-size: 1rem;
  margin: 1rem 0;
}
.followus{
  font-size: 1.25rem;
  margin-top: 30px;
}
.footer-quotes {
  font-size: 1rem;
  margin: 1rem 0;
  font-family: "Verdana, Geneva, Tahoma, sans-serif";
}

.link-column{
  margin: 1rem auto;
}

.links-column ul {
  list-style: none;
  padding: 0;
}

.links-column ul li {
  margin: 0.5rem 0;
}

.address-column p {
  margin: 0.5rem 0;
}

.social-icons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.social-icons a {
  color: white;
  font-size: 1.5rem;
  text-decoration: none;
}

.map {
  width: 100%;
  height: 200px;
  margin-top: 1rem;
}

.footer-bottom {
  text-align: center;
  padding: 1rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-logo{
    width: 100px;
    margin-left: 8rem;
  }

  .footer-quotes{
    margin: 1rem auto;
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }

  .address-column p{
    margin: 0.5rem auto;
    text-align: center;
    width: 50%;
  }

  .map{
    width: 60%;
    margin: 0.5rem auto;
  }

  .followus{
    text-align: center;
  }
  
}

@media (min-width: 320px) and (max-width: 480px) {
  .footer-column {
    flex: none;
    width: 100%;
    margin:10px;
  }

  .social-icons {
    justify-content: center;
  }

  .footer-logo{
    margin: auto;
  }

  .footer-title{
    text-align: center;
  }
  .footer-quotes {
    text-align: center;
  }

  .address-column p{
      margin: 0.5rem auto;
      text-align: center;
      width: 100%;
  }

  .map {
    width: 90%;
    margin: 0.5rem auto;
  }

}


.terms{
 position: relative;
 top: 30px;
}
.cancel{
  position: relative;
 top: 30px;
}
@media (max-width: 480px) {
  .terms {
    top: 10px; 
  }

  .cancel {
    top: 10px; 
  }
}