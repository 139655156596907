.logo-details-container {
    text-align: center;
    padding: 2rem;
    margin-top: 100px;
  }
  
  .logo-header > h2 {
    font-size: 2rem;
    color: #555555;
  }
  
  .logo-header > p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .logo-image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .logo-image {
    position: relative;
    width: 60%;
    padding: 20px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: opacity 0.3s, transform 0.3s;
    margin-bottom: 20px;
  }
  
  .logo-image.highlight {
    opacity: 1;
    transform: scale(1.05);
    border: 2px solid #ff6347; 
    box-shadow: 0 4px 12px rgba(255, 99, 71, 0.4);
  }
  
  .logo-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .logo-image h3 {
    margin: 0;
    padding: 10px 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .logo-image p {
    margin: 0 0 10px;
    padding: 0 10px;
    font-size: 1rem;
    color: #666;
  }
  
  .zoomImg {
    max-width: 50vw; 
    max-height: 50vh; 
  }
  
  @media (max-width: 768px) {
    .logo-details-container {
      padding: 1rem;
    }
  
    .logo-image {
      width: 100%;
    }
  
    .logo-image h3 {
      font-size: 1.25rem;
    }
  
    .logo-image p {
      font-size: 0.9rem;
    }
  
    .zoomImg {
      max-width: 100vw;
      max-height: 100vh;
    }
  }
  