.contact-us {
    padding: 20px;
    font-family: 'Arial, sans-serif';
    margin: 8rem 2rem;
  }
  
  .contact-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-header h2 {
    font-size: 2rem;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    color: #555555;
    margin-bottom: 10px;
  }
  
  /* .contact-header img {
    width: 100%;
    max-width: 400px;
    height: 200px;
  } */

  .contact-image-container{
    background-image: url("https://i.ibb.co/1fCZ15r/contact-us-communication-support-service-assistance-concept-1-11zon.jpg");
    background-position: center;
    background-size: contain;
  }
  
  .contact-cards {
    display: flex;
    justify-content: space-around;
    margin: 0 auto 20px;
    flex-wrap: wrap;
    width: 80%;
  }
  
  .contact-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 30%;
    margin: 10px 5px;
    text-align: center;
    transition: transform 0.3s;
  }

  .contact-card h3{
    font-size: 1.5rem;
  }
  
  .contact-card:hover {
    transform: scale(1.05);
  }
  
  .contact-icon {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .social-icons-contact {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
  }
  
  .social-icon-contact {
    font-size: 24px;
    margin: 0 10px;
    transition: color 0.3s;
  }
  
  .social-icon-contact:hover {
    color: currentColor;
  }
  
  .address-map-card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto 0;
    width: 80%;
  }
  
  /* .address-contact {
    width: 45%;
  } */
  
  .address-contact-container{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .address-contact a {
    color: inherit;
    text-decoration: none;
  }
  
  .address-contact a:hover {
    text-decoration: underline;
  }
  
  .map-contact {
    width: 45%;
    height: 300px;
    z-index: 1; 
    position: static;
  }

  .address-contact h3{
    font-size: 2rem;
  }
  
  @media (max-width: 768px) {
    .contact-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-card {
      width: 80%;
    }
  
    .address-map-card {
      flex-direction: column;
      align-items: center;
    }
  
    .address-contact, .map-contact {
      width: 80%;
    }
  }
  
  @media (min-width: 320px) and (max-width: 480px) {

    .contact-us {
      font-family: 'Arial, sans-serif';
      margin: 8rem 2rem;
      padding: 0;
  }

    .contact-cards{
      width: 120%;
      
    }

    .contact-header h2 {
      font-size: 18px;
    }

    .contact-cards{
      width: 100%;
    }
  
    .contact-card {
      width: 100%;
    }
  
    .address-map-card{
      width: 100%;
    }

    .address-contact-container{
      width: 100%;
    }

    .address-contact, .map-contact {
      width: 100%;
    }
  }
  