.therapy-list-container {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  margin: 80px auto;
  max-width: 87%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.therapy-list-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.therapy-list-container #therapies-id {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: #0d6efd;
  margin-bottom: 2rem;
}

.therapy-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  justify-items: center;
}

.read-more-button-container {
  text-align: center;
  margin-top: 2rem;
}

.read-more-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #00A4E4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.read-more-button:hover {
  background-color: #007ba7;
}

@media (max-width: 1024px) {
  .therapy-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .therapy-list-container #therapies-id {
    font-size: 2rem;
  }

  .read-more-button {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .therapy-list-container {
    width: 80%;
  }
  .therapy-list {
    grid-template-columns: 1fr;
  }

  .therapy-list-container #therapies-id {
    font-size: 1.5rem;
  }

  .read-more-button {
    font-size: 0.8rem;
  }

  .therapy-list-container {
    opacity: 1; 
    transform: translateY(0); 
  }
}

@media (max-width: 480px) {
  .therapy-list-container {
    padding: 1rem;
    width: 80%;
  }

  .therapy-list-container #therapies-id {
    font-size: 1.25rem;
  }

  .read-more-button {
    font-size: 0.75rem;
  }
}
