.article-section {
  padding: 20px;
  background-color: #f9f9f9;
  width: 87%;
  margin: 2rem auto;
  border-radius: 5px;
  overflow: hidden; /* Prevent any overflow issues */
}

.carousel-section {
  width: 75%;
  margin: 0 auto 50px;
  clear: both; /* Ensure it appears after the row div */
}

.carousel-section h2 {
  
  margin-bottom: 20px;
  font-size: 2rem;
  color: #555555;
  font-weight: 700;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

/* Fixed height for carousel items */
.carousel-item-research {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px; /* Set a fixed height */
}

.carousel-item-news {
  height: 350px; /* Set a fixed height */
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.carousel-item img {
  width: 80%;
  height: 90%; /* Occupy 90% of the container */
  margin: 0 auto;
  /* object-fit: cover; Ensure image aspect ratio is maintained */
}

.carousel-item p {
  padding: 10px;
  font-size: 14px;
  color: #333;
}

.carousel-item-research p {
  margin-bottom: 25px;
}

.carousel-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.row {
  height: auto; /* Let content define the height */
  margin-bottom: 20px; /* Ensure spacing below the row */
  position: relative;
}

.read-more-button {
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  background-color: #00A4E4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Responsive Design */

/* General Styling */
@media (max-width: 768px) {
  .carousel-section {
    width: 90%;
    margin: 0 auto 50px;
  }

  .carousel-section h2 {
    font-size: 20px;
  }

  .carousel-item {
    height: 300px; /* Adjust height for smaller screens */
  }

  .carousel-item img {
    height: 60%; /* Adjust image height */
  }

  .row {
    margin-bottom: 20px; /* Ensure spacing in mobile view */
  }

  /* Reduce the size of navigation buttons */
  .swiper-button-prev,
  .swiper-button-next {
    width: 15px; /* Adjusted size */
    height: 15px; /* Adjusted size */
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin-top: 5px; /* Position pagination below the content */
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .carousel-section {
    width: 100%;
    margin: 0 auto 20px; /* Ensure spacing is consistent */
  }

  .carousel-section h2 {
    font-size: 18px;
  }

  .carousel-item {
    height: 280px; /* Adjust height for mobile screens */
  }

  .carousel-item img {
    height: 60%; /* Adjust image height */
  }

  .row {
    margin-bottom: 20px; /* Ensure spacing in mobile view */
  }

  /* Specific adjustments for news articles */
  .carousel-section.carousel-news h2 {
    margin-bottom: 10px; /* Reduced space for news article heading */
  }

  .carousel-item-news {
    height: 350px; /* Height for news articles */
  }

  /* Reduce the size of navigation buttons */
  .swiper-button-prev,
  .swiper-button-next {
    width: 10px; /* Further adjusted size */
    height: 10px; /* Further adjusted size */
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
  }

  /* Reduce space between image and pagination bullets for news carousel */
  .carousel-item-news .swiper-pagination {
    margin-top: 0; /* Removes any space between image and bullets */
    padding-top: 10px; /* Adds some padding above the bullets */
  }
}
