.member-container{
    margin: 80px 0;
  }
  
  .member-header > h2{
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: #555555;
    margin: 1.5rem 0;
    transform: translateY(0);
    opacity: 1;
  }
  
  .slider-container {
   
    padding: 20px;
    border-radius: 10px;  
  }
  
  .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    cursor: pointer;
  }
  
  .logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.3s;
  }
  
  .logo-wrapper img:hover {
    transform: scale(1.1);
  }
  
  @media (min-width:320px) and (max-width:480px) {
    .logo-wrapper img{
      max-width: 80%;
      max-height: 50px;
      margin-right: 30px;
    }

    .logo-wrapper{
      width: 100%;
      height: 50px;
    }

    .member-header > h2{
      font-size: 1.5rem;
    }
  }
