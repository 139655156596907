/* major updates */
.booking-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    margin-top: 100px;
  }

  .react-calendar__tile--now {
    background-color: blue !important;
    color: white !important;
  }
  
  .booking-form h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #555555;
    margin-bottom: 20px;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .form-input-img-container{
    display: flex;
  }
  
  .inputs-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .inputs-container input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .calendar-and-slots {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .left-side {
    width: 50%;
  }
  
  .right-side {
    margin-top: 3.5rem;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .time-slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    border: 2px solid black;
  }
  
  .slot-button {
    padding: 10px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .slot-button.booked {
    background-color: #f44336;
    cursor: not-allowed;
  }
  
  .slot-button.selected {
    background-color: skyblue;
  }
  
  .slot-button:hover:not(.booked):not(.selected) {
    background-color: #45a049;
  }

  .btn-book{
    background-color: #00A4E4;
    color: #fff;
    width: 50%;
    padding: 1rem 2rem;
    font-size: 0.75rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .btn-book:hover{
    background-color: #066c94;
  }
  
  .message {
    text-align: center;
  }
  
  .message p {
    margin: 10px 0;
  }
  
  .message button {
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
    background-color: #4caf50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .message button:hover {
    background-color: #45a049;
  }

  .policy-checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 1rem;
  }
  
  #privacy-policy{
    width: 10%;
  }

  .policy-link {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }

  .submit-button{
    background-color: #4CAF50;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    margin-top: 1rem;
  }

  /* Spinner Style */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #00A4E4;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

  
  @media(max-width: 768px) {
    .form-container{
      width: 100%;
    }
    .form-input-img-container{
      width: 100%;
    }
  }

  @media (min-width:320px) and (max-width:480px) {

    .form-container{
      width: 93%;
    }

    .form-input-img-container{
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
      align-items: center;
    }
    .inputs-container{
      width: 100%;
    }
    .calendar-and-slots{
      display: flex;
      flex-direction: column;
    }
    .left-side{
      width: 100%;
    }
    .right-side{
      width: 100%;
      margin-top: 0.5rem;
    }
    .btn-book{
      padding: 0.5rem 0.25rem;
    }
    .image-container{
      width: 140%;
      margin-left: 1.3rem;
      margin-bottom: 1rem;
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    margin: 1rem auto;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .warning-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
  }
  