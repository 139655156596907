.video-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /* margin: auto; */
    margin: 80px auto;
    width: 87%;
}
  
  .video-section-content {
    display: flex;
    flex-direction: row;
    gap: 70px;
    max-width: 1200px;
    width: 100%;
   }

   .video-section-video {
    width: 60%;
   }
  
  .video-section-video iframe {
    width: 100%;
    height: auto;
    min-height: 300px;
  }
  
  .video-section-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    text-align: center;
  }
  
  .video-quote {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .video-quote i {
    color: #666;
    margin-right: 5px;
  }

  i.fas.fa-quote-left {
    margin-right: 150px;
  }

  i.fas.fa-quote-right {
    margin-left: 170px;
  }
  
  /* Tablet (Max-width: 768px) */
  @media screen and (max-width: 768px) {
    .video-section-content {
      flex-direction: column;
    }
  
    .video-section-video iframe {
      height: 250px;
    }
  
    .video-quote {
      font-size: 20px;
    }
  }
  
  /* Mobile (Min-width: 320px and Max-width: 480px) */
  @media screen and (min-width: 320px) and (max-width: 480px) {
    .video-section-content {
      flex-direction: column-reverse;
    }
  
    .video-section-video iframe {
      height: 200px;
    }
  
    .video-quote {
      font-size: 18px;
    }

    .video-section-video {
        width: 100%;
    }

    .video-section-container{
        width: 100%;
        padding: 0px;
    }
  }
  
  
  
  
  
  