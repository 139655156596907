.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: #fff;
    border-radius: 50%;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    cursor: pointer;
    z-index: 1000;
  }
  
  .menu-open .whatsapp-icon {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  