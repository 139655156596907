
.naturopathy-therapy-container {
    padding: 2rem;
    text-align: center;
  }
  
  .therapy-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 2rem auto;
  }