.about-us-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 100px;
  min-height: 100vh;
  padding: 2rem;
}

.about-us-background {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  text-align: center;
}

.about-us-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}

.carousel-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-container img {
  width: 100%;
  height: 600px; 

/* 
  object-fit: cover; */
  border-radius: 10px;
}

.about-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
}

.about-buttons button {
  flex: 1;
  background-color: #4CAF50; 
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s, transform 0.3s;
}

.about-buttons button:hover {
  background-color: #45a049; 
  transform: scale(1.05); 
}

.about-buttons button.active {
  background-color: #2196F3; 
  outline: 2px solid #2196F3; 
}

.tab-content {
  margin-top: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  padding: 1rem;
  border-radius: 10px;
  width: 100%;
  text-align: justify;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 200px;
}


.tab-content h2 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #555555;
}

.tab-content p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

.tab-content ul>li{
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

.tab-content.aim {
  border-left: 4px solid #2196F3;
}

.tab-content.vision {
  border-left: 4px solid #2196F3;
}

.tab-content.mission {
  border-left: 4px solid #2196F3;
}

.tab-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.about-us-text {
  flex: 1;
  position: relative;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2.2rem 2rem;
  border-radius: 10px;
  color: black;
}

.about-us-text h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #555555;
  font-weight: 500;
}

.about-us-text p {
  font-size: 1.15rem;
  line-height: 1.6;
  text-align: justify;
  color: #555555;
  margin-bottom: 1rem;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}


@media (max-width: 1024px) {
  .about-us-container {
    padding: 1rem;
  }

  .about-us-background {
    padding: 1.5rem;
  }

  .about-us-content {
    flex-direction: column;
  }

  .about-us-text h1 {
    font-size: 2rem;
  }

  .about-us-text p {
    font-size: 1rem;
  }

  .carousel-container {
    flex: none;
  }
}

@media (max-width: 768px) {
  .about-us-container {
    padding: 1rem;
  }

  .about-us-background {
    padding: 1rem;
  }

  .about-us-content {
    flex-direction: column;
  }

  .about-us-text h1 {
    font-size: 1.75rem;
  }

  .about-us-text p {
    font-size: 0.875rem;
  }

  .carousel-container img {
    height: 300px;
  }
}

@media(min-width: 320px) and (max-width: 480px) {
  .about-us-container {
    padding: 0.5rem;
  }

  .about-us-background {
    padding: 0.5rem;
  }

  .about-us-content {
    flex-direction: column;
  }

  .about-us-text h1 {
    font-size: 1.5rem;
  }

  .about-us-text p {
    font-size: 1rem;
  }

  .carousel-container img {
    height: 200px;
  }

  .tab-content {
    width: 100%;
    position: relative;
    left: -2%;
    text-align: justify;
    height: auto;
  }

  .tab-content h1 {
    font-size: 1.5rem;
  }

  .tab-content p {
    font-size: 0.85rem;
  }

  .tab-content h2{
    font-size: 1.5rem;
  }

}



.about-us-text {
  font-family: Arial, sans-serif;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ul-l {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
}

.list-1, .list-2 {
  flex: 1 1 45%; 
  min-width: 200px; 
}

.list-1 {
  display: flex;
  flex-direction: column;
}

.list-2 {
  display: flex;
  flex-direction: column;
}

.list-1 li, .list-2 li {
  margin-bottom: 10px; 
  display: flex;
  align-items: center;
}

.fa-arrow-right, .fa-chevron-circle-right, .fa-arrow-circle-right, .far fa-hand-point-right {
  margin-right: 8px; 
}
