.benefits-container {
    display: flex;
    justify-content: space-between;
    width: 87%;
    margin: 10px auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .benefits-container.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .bimage-container,
  .bcontent-container {
    width: 48%;
  }
  
  .bimage-container img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .btext-container {
    text-align: justify;
  }

  .btext-container > p:first-of-type{
    text-indent: 2rem;
  }
  .btext-container p{
    margin: 1rem 0;
    font-family: "PT Sans", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #555;
  }
  
  .btext-container h1 {
    font-size: 2rem;
    color: #555555;
    font-weight: bold;
    text-align: center;
  }
  
  .btext-container ul {
    list-style-type: none;
    padding: 0;
    margin-left: 5rem;
  }
  
  .btext-container ul li {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .btext-container ul li svg {
    margin-right: 10px;
  }
  
  .btable-container {
    margin-top: 10%;
    overflow-x: auto;
    position: relative;
    right: 60%;
    width: 600px;
  }

  .btable-container h2{
    /* color: #555; */
    font-size: 1rem;
    font-weight: 500;
    color: #555555;
  }
  
  .btable-container table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .btable-container th,
  .btable-container td {
    padding: 12px;
    border: 1px solid #ddd;
    white-space: nowrap; 
  }
  
  .btable-container th {
    background-color: #f4f4f4;
    font-weight: bold;
    font-size: 1.25rem;
    font-weight: 500;
    color: #555555;
  }
  
  .btable-container td {
    font-weight: normal;
    font-family: "PT Sans", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #555;
  }
  
  /* .btable-container td::before {
    content: '→ ';
    font-weight: bold;
  } */
  
  @media (max-width: 768px) {
    .benefits-container {
      flex-direction: column;
      width: 80%;
      padding: 15px;
    }
  
    .bimage-container,
    .bcontent-container {
      width: 100%;
    }
  
    .btext-container p{
        font-size: 1rem;
    }

    .btext-container ul {
      margin-left: 2rem;
    }
  
    .btext-container ul li {
      margin: 5px 0;
    }
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .btext-container p:first-of-type {
      text-align: justify;
    }
  
    .btext-container p:nth-of-type(2) {
      margin-top: 1rem;
      text-align: left;
      text-decoration: underline;
      font-size: 1.05rem;
      font-weight: 500;
      color: #555555;
    }

    .btext-container p{
        font-size: 0.85rem;
    }
  
    .btext-container ul {
      margin-left: 1rem;
    }
  
    .btext-container ul li {
      font-size: 0.9rem;
    }
  
    .btable-container th, .btable-container td {
      font-size: 0.85rem;
      padding: 8px;
      word-wrap: break-word; 
    }
  
    .btable-container  {
      overflow-x: auto;
      display: block;
      width: 90%;
      position: relative;
      left: 5%;
    }
  
    .btable-container th, .btable-container td {
      white-space: normal; 
    }
  }
  