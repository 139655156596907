.testimonial-section {
  padding: 2rem;
  text-align: center;
  width: 90%;
  margin: 80px auto;
}

.testimonial-section > h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #555555;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background:#e6e6fa;  
  padding: 1.5rem;
  border-radius: 10px;
  color:#000000;
  margin-top: 1rem;
  height: 450px;
}

.testimonial img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
 
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
  flex-grow: 1;
}

.testimonial-content p {
  font-size: 1rem;
  line-height: 1.4;
}

.testimonial h3 {
  margin-top: 1rem;
  font-size: 1.1rem;
}

.testimonial .date {
  font-size: 0.85rem;
  opacity: 0.8;
}


@media (min-width: 1024px) {
  .testimonial-section > h2 {
    font-size: 2.5rem;
  }
  .testimonial {
    height: 450px;
  }
  .testimonial-content p {
    font-size: 1rem;
  }
}


@media (max-width: 1024px) {
  .testimonial-section {
    width: 95%;
  }
  .testimonial-section > h2 {
    font-size: 2rem;
  }
  .testimonial {
    height: 500px;
  }
 
  .testimonial-content{
    height: 250px;
  }
  .testimonial-content p {
    font-size: 1rem;
  }
}


@media (max-width: 768px) {
  .testimonial-section {
    width: 100%;
    padding: 1.5rem;
  }
  .testimonial {
    height: 500px;
    padding: 1.2rem;
  }
  .testimonial-content p {
    font-size: 0.95rem;
  }
  .testimonial h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 425px) {
  .testimonial-section {
    width: 100%;
    padding: 1.5rem;
  }
  .testimonial {
    height: 500px;
    padding: 1rem;
  }
  .testimonial img {
    width: 90px;
    height: 90px;
  }
  .testimonial-content p {
    font-size: 0.9rem;
    text-align: left;
  }
  .testimonial h3 {
    font-size: 0.95rem;
  }
  .testimonial .date {
    font-size: 0.75rem;
    text-align: center; 
  }
}


@media (max-width: 375px) {
  .testimonial-section {
    width: 100%;
    padding: 1.2rem;
  }
  .testimonial {
    height:400px;
    padding: 0.8rem;
  }
  .testimonial img {
    width: 80px;
    height: 80px;
  }
  .testimonial-content p {
    font-size: 0.85rem;
    text-align: left;
  }
  .testimonial h3 {
    font-size: 0.9rem;
  }
  .testimonial .date {
    font-size: 0.75rem;
  }
}


@media (max-width: 320px) {
  .testimonial-section {
    width: 100%;
    padding: 1rem;
  }
  .testimonial {
    height: 400px;
    padding: 0.7rem;
  }
  .testimonial img {
    width: 75px;
    height: 75px;
  }
  .testimonial-content p {
    font-size: 0.8rem;
    text-align: left;
  }
  .testimonial h3 {
    font-size: 0.85rem;
  }
  .testimonial .date {
    font-size: 0.7rem;
    text-align: center; 
  }
}
