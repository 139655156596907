.shop-local-container {
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  color: #555;
  width: 87%;
  margin: 80px auto;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.shop-local-container p {
  text-align: justify;
}

.about-read-button{
  display: flex;
  justify-content: center;
}

.text-container p{
  text-indent: 2rem;
  font-family: "PT Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #555;
}

.text-container li{
  list-style-type: none;
}

@media (max-width: 1024px) {
  .shop-local-container{
    display: flex;
    flex-direction: column;
    max-width: 70%;
  }
}

@media (max-width: 768px) {
  .shop-local-container{
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-top: 20px;
  }
  .about-read-button {
    padding: 0.75rem 0rem;
    font-size: 0.5rem;
    font-weight: bold;
    color: #fff;
    background-color: #00A4E4;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 30%;
    transition: background-color 0.3s;
    margin-right: 20px;
  }
  .image-container img {
    width: 200%;
    height: 200px;
    object-fit: cover;
  }
}

.shop-local-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.image-container {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.image-container img {
  max-width: 95%;
  height: 95%;
  border-radius: 8px;
}

.text-container {
  flex: 3;
}

.text-container h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #555555;
  margin-bottom: 10px;
  text-align: center;
  margin-right: 2rem;
}

.text-container p {
  margin-bottom: 10px;
  line-height: 1.6;
}

.about-read-button {
  padding: 0.75rem 1.80rem;
  width: 30%;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  background-color: #00A4E4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 auto;
  
}

@media (min-width:320px) and (max-width:480px) {
 .text-container p{
  text-align: justify;
  font-size: 0.85rem;
 }

 .text-container h1{
  font-size: 1.5rem;
  margin-left: 1rem;
 }
 
 .shop-local-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 15px;
 }

 .about-read-button{
  margin: 1rem auto;
  padding: 10px 10px;
  width: 50%;
  
 }

}

.text-container ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 3.5px;
  padding-left: 20px;
  list-style-type: disc;
  margin-bottom: 20px;
}

.text-container li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 8px;
}


@media screen and (max-width: 768px) {
  .text-container ul {
    grid-template-columns: 1fr; 
  }
}
