.therapy-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
  width: 100%;
  background-color: #fff;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.therapy-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.therapy-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.therapy-card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
}

.therapy-card-content h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: #555555;
}

.therapy-card-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00A4E4;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;
  cursor: pointer;
}

.arrow-icon {
  margin-left: 8px;
}
