.floating-consultation {
    position: fixed;
    bottom: 100px;
    right: 20px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #0d6efd;
    color: #fff;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    font-weight: bold;
    z-index: 1001;
    animation: moveUpDown 2s infinite;
  }
  
  .menu-open .floating-consultation {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  
  .floating-consultation .fas.fa-stethoscope {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  