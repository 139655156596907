/* Styling for form container */
.form-container {
  max-width: 800px;
  margin: 150px auto 50px;
  padding: 30px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  font-size: 2rem;
  color: #555555;
  margin-bottom: 25px;
  font-weight: 700;
}

.form-grid {
  display: flex;
  justify-content: space-between;
}

.form-left,
.form-right {
  width: 48%;
}

.form-group {
  margin-bottom: 20px;
}

/* Styling for labels */
label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input,
select,
textarea {
  width: 300px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

textarea {
  resize: none;
  height: 60px;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #0d6efd;
  outline: none;
}

.submit-button {
  width: 70%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #0d6efd;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-column {
  flex: 1;
  margin-right: 10px;
}

.form-column:last-child {
  margin-right: 0;
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Dimmed background */
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center;     /* Centers content vertically */
  z-index: 1000;
}

/* Centering spinner and text */
.spinner-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensures the spinner and text are centered horizontally */
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db; /* Adjust color for better contrast */
  border-radius: 50%;
  width: 40px; /* Adjust size */
  height: 40px; /* Adjust size */
  animation: spin 1.5s linear infinite;
  margin-bottom: 10px; /* Space between spinner and text */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-content p {
  font-size: 1.2rem;
  color: #555;
  text-align: center; /* Ensures the text is centered */
}

/* Success and Error Modal */
.success-modal,
.error-modal {
  width: 50%;
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.success-modal h2,
.error-modal h2 {
  margin-bottom: 20px;
  color: #333;
}

/* Styling buttons inside modals */
.success-modal button,
.error-modal button {
  width: 40%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #0d6efd;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  margin-right: 1rem;
}

.success-modal button:hover,
.error-modal button:hover {
  background-color: #0056b3; /* Darken on hover */
}

@media (max-width: 768px) {
  .form-left,
  .form-right {
    width: 100%;
    padding: 0;
  }

  .form-group {
    margin-bottom: 15px;
  }

  h2 {
    font-size: 24px;
  }

  .success-modal button,
  .error-modal button {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .form-grid {
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 12px;
  }

  h2 {
    font-size: 20px;
  }

  input,
  select,
  textarea {
    font-size: 14px;
    padding: 8px;
  }

  .submit-button {
    font-size: 14px;
    padding: 8px;
  }

  .success-modal,
  .error-modal {
    width: 80%;
    background: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  }

  .success-modal button,
  .error-modal button {
    width: 80%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #0d6efd; /* Same as "Book Slot" button */
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }
}
