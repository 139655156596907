.therapy-overview-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  margin-top: 6.5rem;
  position: relative;
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.therapy-overview-content {
  flex: 1 1 70%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideInLeft 1s ease-out;
  
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.therapy-overview-content h3 {
  text-align: center;
  font-weight: 500;
  color: #555555;
  font-size: 2em;
  margin-bottom: 5px;
}

.therapy-overview-image {
  width: 80%;
  height: auto;
  margin: 0 auto 0;
  margin-bottom: 20px;
  border-radius: 8px;
}

.therapy-overview-content p {
  font-size: 2 rem;
  line-height: 1.5;
  margin: 10px auto;
  text-align: justify;
  width: 80%;
  color: #555;
}

.therapy-overview-sidebar {
  flex: 1 1 25%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 100px;
  max-height: calc(100vh - 120px); 
  overflow-y: auto;
  animation: slideInRight 1s ease-out;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.therapy-overview-sidebar h2 {
  text-align: center;
  font-size: 1.5em;
  font-weight: 500;
  color: #555555;
  margin-bottom: 20px;
}

.therapy-overview-list {
  list-style: none;
  padding: 0;
}

.therapy-overview-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s, color 0.3s;
}

.therapy-overview-item{
  font-size: 1rem;
  font-weight: 500;
  color: #555555;
}

.therapy-overview-item:hover,
.therapy-overview-item.active {
  background-color: #9DDE8B;
  color: #fff;
}


.therapy-overview-sidebar::-webkit-scrollbar {
  width: 8px;
}

.therapy-overview-sidebar::-webkit-scrollbar-thumb {
  background: #9DDE8B;
  border-radius: 4px;
}

.therapy-overview-sidebar::-webkit-scrollbar-thumb:hover {
  background: #9DDE8B;
}

.therapy-treatment-section {
  margin-top: 20px;
}

.therapy-treatment-section h4{
  text-align: center;
  font-family: inter, helvetica, sans serif !important;
  font-size: 36px;
  font-weight: 400;
  color: #555;
}

.therapy-treatment-images {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  border: 1px solid black;
  width: 400px;
  margin-left: 200px;
  padding: 5px;
}

.treatment-image-item {
  flex: 1;
  text-align: center;
}

.treatment-image-item h5{
  font-family: inter, helvetica, sans serif !important;
  font-size: 20px;
  font-weight: 400;
  color: #555;
}

.treatment-image-item img {
  max-width: 70%;
  height: 200px;
  display: block;
  margin: 0 auto;
  /* object-fit: contain; */
}


@media (max-width: 1024px) {
  .therapy-overview-container {
    flex-direction: column;
  }

  .therapy-overview-content,
  .therapy-overview-sidebar {
    width: 100%;
    position: static;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .therapy-overview-container {
    flex-direction: column;
  }

  .therapy-overview-content {
    order: 1;
  }

  .therapy-overview-sidebar {
    order: 2;
    position: static; 
  }
  
  .therapy-treatment-images {
    flex-direction: row;
    gap: 10px;
  }

  .treatment-image-item img {
    max-width: 90%;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .therapy-treatment-images {
    flex-direction: row;
    gap: 10px;
    
  }

  .treatment-image-item img {
    max-width: 40%;
    height: auto;
  }
}

/* 
.point-container {
  margin-left: 140px;
  
}

.point-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.point-container li {
list-style-type: none;
  line-height: 1.6;
  margin: 10px 0;
}


.point-container ul {
  
  margin-left: 20px;
}

.point-container.points1 ul {
  margin-left: 20px; 
} */



.point-container {
  margin: 0 auto; 
  padding: 0 20px; 
  margin-left: 140px;
}

.point-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.point-container li {
  line-height: 1.6;
  margin: 10px 0;
}


@media (max-width: 1024px) {
  .therapy-overview-container {
    flex-direction: column;
  }

  .therapy-overview-content,
  .therapy-overview-sidebar {
    width: 100%;
    position: static;
    margin-bottom: 20px;
  }

  .point-container {
    margin-left: 0; 
    padding: 0 10px; 
  }
}

@media (max-width: 768px) {
  .therapy-overview-container {
    flex-direction: column;
  }

  .therapy-overview-content {
    order: 1;
   
  }

  .therapy-overview-sidebar {
    order: 2;
    position: static; 
  }
  
  .therapy-treatment-images {
    flex-direction: column; 
    gap: 10px;
    margin-left: 0; 
    width: auto; 
  }

  .treatment-image-item img {
    max-width: 100%;
    height: auto;
  }

  .point-container {
    margin-left: 0; 
    padding: 0 10px; 
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .therapy-treatment-images {
    flex-direction: row; 
    gap: 10px;
    margin: 0; 
    margin-left: 30px; 
    width: 200px; 
  }

  .treatment-image-item img {
    max-width: 40%; 
    height: auto;
  }

  .point-container {
    margin-left: 30px; 
    padding: 0 5px; 
  }
}



#subtitle{
text-align: center;
font-size: 20px; 

}
