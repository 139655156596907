


.gallery-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  background-size: cover;
  width: 80%;
  margin: 80px auto 40px;
  border-radius: 10px;
  
}


.gallery-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    "title first"
    "second third"
    "fourth fifth"
    "six seemore";
  gap: 20px;
  max-width: 1000px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

/* See More Button */
/* .see-more {
  grid-area: seemore;
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #555555;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 20px;
  font-weight: 600;
  font-size: 3rem;
  width: 100%;
  height: 100px;
  transition: background-color 0.3s ease, color 0.3s ease;
  opacity: 1;
  transform: translateY(0);
} */

.see-more:hover {
  background-color: #f0f0f0;
  color: #000;
}

.see-more-icon {
  margin-left: 10px;
  font-size: 2rem;
}


@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "first"
      "second"
      "third"
      "fourth"
      "fifth"
      "six"
      "see-more";
  }

  .see-more {
    font-size: 2rem;
    height: auto;
    padding: 0;
    margin: 0;
  }
}

/* Extra small devices */
@media (min-width: 320px) and (max-width: 480px) {
  .gallery-container {
    margin-top: 20px;
    width: 95%;
  }

  .gallery-title h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #555555;
  }
}


.gallery-title {
  grid-area: title;
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  border-radius: 10px;
}

.gallery-title img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-left: 1rem;
}

.gallery-title h2 {
  font-size: 3rem;
  color: #555555;
}

.gallery-info {
  color: #fff;
  background-color: #00000094;
  border-radius: 1rem;
  width: 100%;
  position: relative;
  top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px; }

.gallery-info-content {
  display: flex;
  align-items: center;
}

.gallery-info-content img {
  margin-right: 10px;
  width: 40px;
  height: auto;
}

.gallery-info-content h2 {
  margin: 0;
  font-size: 1rem; 
  color: #fff;
}

.gallery-info-logo {
  width: 10%;
  margin-right: 1rem;
}


/* .gallery-section {
  display: block;
  text-decoration: none;
  color: #000;
  position: relative;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-out, transform 1s ease-out;
} */

.gallery-section.show {
  opacity: 1;
  transform: translateY(0);
}


.gallery-image-wrapper {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  
}

.gallery-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
 
}

.head{
  font-size: 2rem;
  color: #555555;
  font-weight: 700;
}

.see-more {
  grid-area: seemore;
 
  display: flex; 
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  font-size: 2rem;
  font-weight: bold;
  color: #555555;
  width: 100%;
  height: 100px;
  transition: background-color 0.3s ease, color 0.3s ease;
  opacity: 1;
  transform: translateY(0);
}

.see-more-icon {
  margin-left: 10px;
  font-size: 2rem; /* Adjust font size of the icon */
  display: inline-block; /* Ensure it stays on the same line */
}

/* Adjustments for mobile */
@media (max-width: 768px) {
  .see-more {
    font-size: 2rem;
    height: auto;
    padding: 0;
    margin: 0;
  }
}

