.research-container {
  margin-top: 10px;
  background-color: #F9F9F9;
  margin-bottom: 40px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px; 
}

.research-title {
  font-size: 2rem;
  font-weight: bold;
  color: #555555;
  text-align: center; 
  margin: 20px 0;
}

.research-para{
  text-align: justify;
}

.research-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
}

.research-image {
  flex: 1 1 100%; 
  padding: 10px;
}

.research-image img {
  width: 250px; 
  height: auto; 
  margin: 5px auto; 
  display: block;
}

.research-info {
  flex: 1 1 100%; 
  padding: 10px;
  font-size: 1.25rem;
}

.research-info h3{
  font-size: 2rem;
}

.research-readmore{
  display: flex;
  justify-content: center;
}

.read-more {
  background-color: #17a2b8; 
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
}

.float-end {
  float: right; 
  max-width: 50%; 
  margin-top: 10px;
}


@media (min-width: 600px) {
  .research-image {
      flex: 1 1 45%; 
  }
  .research-info {
      flex: 1 1 45%;
  }
}

@media (min-width: 900px) {
  .research-image {
      flex: 1 1 33%; 
  }
  .research-info {
      flex: 1 1 66%;
  }
}
