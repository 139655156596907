/*Major Updates*/
.section-container {
  display: flex;
  padding: 20px;
  margin-top: 6rem;
}

.sidebar {
  width: 350px;
  background-color: #f4f4f4;
  padding: 10px;
  position: sticky;
  top: 90px;
  align-self: flex-start;
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
}

.sidebar-link,
.sidebar-sub-link {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #000;
  margin-bottom: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar-link:hover,
.sidebar-sub-link:hover {
  background-color: #ddd;
}

.sidebar-link.active,
.sidebar-sub-link.active-sub-link {
  background-color: #ffebcc; 
  color: #ff4500; 
  font-weight: bold;
}

.sidebar-sub-link.active-sub-link{
  background-color: #ddd;
  color: #000;
  font-weight: 500;
}

.sidebar-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  text-align: left;
  border: none;
  background: none;
  font-size: 17px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar-button.active {
  background-color:  #DDDDDD; 
  /* color: #DDDDDD ;  */
}

.accordion-icon {
  font-size: 18px;
}

.accordion-content {
  display: none;
  padding-left: 20px;
}

.accordion-content.open {
  display: block;
}

.images-container {
  flex: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.image-card {
  width: 60%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-card img {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
}

@media (max-width: 768px) {
  .section-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: relative;
    top: auto;
    overflow-y: visible;
    margin-bottom: 20px;
    max-height: none;
    order: 2;
  }

  .images-container {
    padding-left: 0;
    order: 1;
  }

  .image-card {
    width: 80%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .image-card {
    width: 100%;
  }
  .images-container {
    margin-bottom: 3rem;
  }
}
