.privacy-policy {
    width: 80%;
    margin: 8rem auto 2rem;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .privacy-policy h1, .privacy-policy h2 {
    color: #007bff;
    font-size: 2rem;

  }
  
  .privacy-policy p {
    line-height: 1.6;
  }

  .privacy-policy h3{
    font-weight: 500;
    margin: 1rem 0;
    text-decoration: underline;
  }

  .privacy-policy ul > li{
    list-style: lower-roman;
    margin-left: 3rem;
  }
