.research-details {
    height: auto;
    padding: 20px;
    max-width: 800px;
    background-color: #f9f9f9;
    margin: 20px auto; 
}

.research-details h2 {
    font-size: 1.5rem; 
    color: #333;
    margin-bottom: 15px;
}

.research-details ul {
    list-style-type: disc;
    padding-left: 20px;
}

.research-details li {
    font-size: 1.125rem; 
    line-height: 1.6;
    color: #555;
    margin-bottom: 10px;
}

.advisor-container {
    margin-top: 120px;
    background-color: #F9F9F9;
    margin-bottom: 40px;
    padding: 20px;
    margin-left: auto; 
    margin-right: auto; 
    max-width: 1200px; 
}

.advisor-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
}

.advisor-image {
    flex: 1 1 100%; 
    padding: 10px;
}

.advisor-image img {
    margin-top: 5px;
    margin: 0 auto;
    width: 250px; 
    display: block; 
}

.advisor-details {
    flex: 1 1 100%; 
    padding: 10px;
    font-size: 1.25rem; 
}

.advisor-details h3{
    font-size: 1.5rem;
}

.paradr {
    font-size: 1rem; 
    text-align: justify;
}

.advisor-role {
    
}

.read-more {
    background-color: #17a2b8; 
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 4px;
}

.float-end {
    float: right; 
    max-width: 50%; 
    margin-top: 10px;
}


@media (min-width: 600px) {
    .advisor-image {
        flex: 1 1 45%; 
    }
    .advisor-details {
        flex: 1 1 45%;
    }
}

@media (min-width: 900px) {
    .advisor-image {
        flex: 1 1 30%; 
    }
    .advisor-details {
        flex: 1 1 65%;
    }
}
