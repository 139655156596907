/* Base Styles */
.landing-page {
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-size 0.5s ease-in-out, background-position 0.5s ease-in-out;
}

.blurred {
    filter: blur(10px);
    pointer-events: none;
    overflow: hidden;
}

.overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.content-grid {
    display: grid;
    min-height: 100vh;
    padding: 0 2rem;
}

.content-container {
    position: relative;
    z-index: 10;
    margin: auto;
    text-align: center;
    color: white;
    top: -10%;
}

.heading-1 {
    font-family: 'Sans-serif';
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.2;
    color: #fff;
    margin: 0 auto 15px;
    line-height: 0.5;
}

.heading-2 {
    font-family: 'Sans-serif';
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #fff;
    margin: 0 auto;
    line-height: 0.5;
}

.description {
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5;
    margin-top: 0.5rem;
    width: 100%;
    max-width: 40rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    text-align: justify !important;
}

/* Floating Consultation */
.floating-consultation {
    position: fixed;
    bottom: 100px;
    right: 20px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #0d6efd;
    color: #fff;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    font-weight: bold;
    z-index: 1001;
    animation: moveUpDown 2s infinite;
}

@keyframes moveUpDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-30px);
    }
}

.consultation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.consultation-container {
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    width: 90%;
    height: 80%;
    color: white;
    max-width: 90%;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
}

.book-slot-button {
    background-color: #0d6efd;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.overall-btn-container {
    position: relative;
    top: 60%;
}

/* Carousel Styles */
.carousel {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.carousel-slide {
    position: relative;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-slide::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.carousel-content {
    position: relative;
    z-index: 2;
    color: white;
    padding: 20px;
    text-align: center;
    animation: slideIn 0.5s ease-in-out;
}

/* Animation for Carousel Content */
@keyframes slideIn {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Breakpoints */

/* Desktop and large screens (min-width: 1280px) */
@media (min-width: 1280px) {
    .heading-1 {
        font-size: 3.5rem;
    }

    .heading-2 {
        font-size: 3.25rem;
    }

    .description {
        font-size: 1.5rem;
        text-align: justify !important;
    }

    .carousel {
        height: 100vh;
    }
}

/* Tablet view and small desktop (max-width: 1024px) */
@media (max-width: 1024px) {
    .heading-1 {
        font-size: 2.2rem;
    }

    .heading-2 {
        font-size: 1.7rem;
        margin-top: 30px;
    } .and-symbol{
        position: absolute;
        top: 40px;
        right: 330px;
    } 


    .description {
        font-size: 1rem;
        text-align: justify !important;
    }

    .landing-page {
        background-size: 120% 100%;
        background-position: center top;
    }
}

/* Tablets and large mobile devices (max-width: 768px) */
@media (max-width: 768px) {
    .heading-1 {
        font-size: 1.75rem;
       
    }

    .heading-2 {
        font-size: 1.3rem;
        margin-top: 30px;
    }
    .and-symbol{
        position: absolute;
        top: 36px;
        right: 200px;
    } 
    


    .description {
        font-size: 1rem;
        text-align: justify !important;
    }

    .landing-page {
        background-size: 140% 100%;
        background-position: center top;
        height: 70vh;
    }

    .banner {
        background-size: 100% 80%;
        background-position: center;
        width: 60%;
        height: 90%;
    }

    .overall-btn-container {
        top: 75%;
    }

    .book-btn {
        padding: 0.40rem 0.75rem;
    }

    .content-container {
        top: -10%;
    }
}
@media (min-width: 426px) and  (max-width: 768px){
    .heading-1 {
        font-size: 1.7rem;
        
    }

    .heading-2 {
        font-size: 1.2rem;
        margin-top: 30px;
    }
    .and-symbol{
        position: absolute;
        top: 36px;
        right: 340px;
    } 

}
/* Small tablets and large mobile devices (max-width: 425px) */
@media (max-width: 425px) {

    .heading-1 {
        font-size: 1.4rem;
        
    }

    .heading-2 {
        font-size: 1rem;
        margin-top: 25px;
    }
    .and-symbol{
        position: absolute;
        top: 32px;
        right: 180px;
    } 

    .carousel {
        height: 60vh;
    }

    .description {
        font-size: 0.925rem;
        text-align: justify !important;
    }

    .landing-page {
        background-size: 150% 100%;
        background-position: center top;
        height: 60vh;
    }
}

/* Small mobile devices (max-width: 375px) */
@media (max-width: 375px) {
    .heading-1 {
        font-size: 1.3rem;
        
    }

    .heading-2 {
        font-size: 1rem;
    }
   

    .description {
        font-size: 0.85rem;
        text-align: justify !important;
    }

    .landing-page {
        background-size: 160% 100%;
        background-position: center top;
        height: 50vh;
    }

    .carousel {
        height: 50vh;
    }

    .overlay {
        height: 50vh;
    }

    .content-container {
        top: -15%;
        left: 5%;
    }

    .banner {
        width: 90%;
        height: 40%;
        background-size: cover;
    }

    .description {
        width: 90%;
        margin: 20px 5px;
        text-align: justify !important;
        top: 20px;
    }
}

/* Extra small mobile devices (max-width: 320px) */
@media (max-width: 320px) {
    .heading-1 {
        font-size: 1.3rem;
    }

    .heading-2 {
        font-size: 1rem;
        margin-top: 30px;
    }
    .and-symbol{
        position: absolute;
        top: 34px;
        right: 160px;
    } 

    .description {
        font-size: 0.825rem;
    }

    .carousel {
        height: 60vh;
    }

    .landing-page {
        background-size: 160% 100%;
        background-position: center top;
        height: 40vh;
    }

    .overlay {
        height: 40vh;
    }

    .content-container {
        top: -20%;
        left: 5%;
    }

    .description {
        width: 90%;
        margin: 20px 5px;
        text-align: justify !important;
        top: 20px;
    }
    .carousel-content{
        margin-top: 50px;
    }
}
