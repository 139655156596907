.consultation-page {
  font-family: 'Arial, sans-serif';
  color: #333;
  padding: 20px;
  text-align: center;
}

.header {
  margin-bottom: 100px;
}

.header h1 {
  font-size: 3em;
  margin-bottom: 10px;
  animation: fadeInDown 1s ease;
}

.header p {
  font-size: 1.2em;
  color: #666;
}

.consultation-card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 95%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1.5s ease;
  margin: 0 auto 40px;
}

.consultation-card h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #555555;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  background: #f1f1f1;
  border: none;
  padding: 10px 20px;
  font-size: 1.1em;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
  transition: background 0.3s;
}

.tab-button.active {
  background: #ddd;
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-header {
  background-color: #f1f1f1;
  border: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
}

.accordion-header:focus {
  outline: none;
}

.accordion-body {
  display: none;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  text-align: left;
}

.accordion-item.active .accordion-body {
  display: block;
}

.consultation-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.option {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 45%;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  animation: fadeInUp 1s ease;
}

.option:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.option h2 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #1a73e8;
}

.option p {
  font-size: 1.1em;
  color: #666;
}

.option button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .consultation-options {
      flex-direction: column;
      align-items: center;
  }

  .option {
      width: 90%;
  }
}

.consultation-page ul {
  padding-left: 20px;
}

.consultation-page li {
  margin-bottom: 10px;
  color: #666;
}

/* Add styles for Naturopathic Treatments section */
.naturopathic-treatments-section {
  background-color: #fff;
  width: 95%;
  padding: 20px;
  margin: 0 auto 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.naturopathic-treatments-section h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #555555;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.naturopathic-treatments-section h5 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.naturopathic-treatments-section p {
  font-size: 1.1em;
  margin: 0 50px;
  margin-bottom: 15px;
  color: #666;
  text-align: left;
}

.naturopathic-treatments-section ul{
  text-align: left;
  margin: 0px 100px;
}

@media (min-width: 320px) and (max-width: 480px) {

  .naturopathic-treatments-section {

    width: 95%;
  }

  .naturopathic-treatments-section h2 {
    font-size: 1.5em;
  }

  .naturopathic-treatments-section h5 {
    font-size: 1em;
    margin-bottom: 15px;
  }

  .naturopathic-treatments-section p{
    margin: 0 10px;
    text-align: justify;
    font-size: 1em;
  }

  .naturopathic-treatments-section ul{
    margin: 0 10px;
  }

}