.gallery-container-nav {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-size: cover;
    width: 80%;
    margin: 80px auto 40px;
  }
  
  .gallery-grid-nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "title first"
      "second third"
      "fourth fifth";
    gap: 20px;
    max-width: 1000px;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
  }
  
  .gallery-title-nav {
    grid-area: title;
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    display: flex;
    gap: 1rem;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    border-radius: 10px;
  }
  
  .gallery-title-nav img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-left: 1rem;
  }
  
  .gallery-title-nav h2 {
    font-size: 3rem;
    color: #555555;
  }
  
  .gallery-section-nav {
    display: block;
    text-decoration: none;
    color: #000;
    position: relative;
  }
  
  .gallery-section-nav-1 { grid-area: first; }
  .gallery-section-nav-2 { grid-area: second; }
  .gallery-section-nav-3 { grid-area: third; }
  .gallery-section-nav-4 { grid-area: fourth; }
  .gallery-section-nav-5 { grid-area: fifth; }
  
  .gallery-image-wrapper-nav {
    position: relative;
    border-radius: 10px;
    overflow: hidden; 
  }
  
  .gallery-image-nav {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px; 
  }
  
  .gallery-overlay-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px; 
  }
  
  .gallery-info-nav {
    color: #fff;
    background-color: #00000094;
    border-radius: 1rem;
    width: 100%;
    position: relative;
    top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .gallery-info-nav h2,
  .gallery-info-nav p {
    margin-left: 1rem;
  }
  
  .gallery-info-logo-nav {
    width: 10%;
    margin-right: 1rem;
  }
  
  @media (max-width: 768px) {
    .gallery-grid-nav {
      grid-template-columns: 1fr;
      grid-template-areas:
        "title"
        "first"
        "second"
        "third"
        "fourth"
        "fifth";
    }
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .gallery-container-nav {
      margin-top: 20px;
      width: 95%;
    }
  
    .gallery-title-nav h2 {
      font-size: 1.5rem;
      font-weight: bold;
      color: #555555;
    }
  }
  