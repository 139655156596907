/* General styles */
.new-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: transparent;
  transition: background-color 0.3s ease;
  z-index: 1000;
  font-family: 'Poppins', sans-serif; /* Uniform font for all views */
  font-weight: 800;
  font-style: normal;
}

.new-navbar.scrolled {
  /* background-color: rgba(0, 0, 0, 0.8); */
  background-color: rgb(35,47,62);
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.logo img {
  width: 6rem;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 100%;
}

.navbar-title {
  font-size: 18px;
  color: #fff;
  transition: opacity 0.3s ease;
}

.navbar-title.hidden {
  opacity: 0;
}

.navbar-title.visible {
  opacity: 1;
}

.navbar-links {
  display: flex;
  align-items: center;
  /*gap: 30px;  Adjust the gap to ensure uniform spacing */
  color: #fff;
  font-family: 'Poppins', sans-serif; /* Uniform font for desktop view */
}

.navbar-links a {
  padding-left: 20px; /* Add padding to align the first letter */
  text-decoration: none;
  color: inherit;
}

.navbar-links a:hover {
  color: #f0f0f0;
}

.separator {
  color: #fff;
  padding: 0 10px;
}

.hamburger {
  display: none;
  cursor: pointer;
  z-index: 1100;
}

.menu-icon, .close-icon {
  font-size: 24px;
  color: #fff;
}

.menu-content {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  max-height: 80vh; /* Limit height */
  overflow-y: auto; /* Make scrollable if content overflows */
}

.menu-content a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left; /* Align text to the left */
  font-family: 'Poppins', sans-serif; /* Uniform font */
  padding-left: 20px; /* Ensure alignment of first letter */
}

.menu-content a:hover {
  text-decoration: underline;
}

.hamburger.open .menu-icon {
  display: none;
}

.hamburger.open .close-icon {
  display: block;
}

/* Dropdown style */
.dropdown {
  position: relative;
  display: inline-block;
  
}

.dropbtn {
  color: #fff;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
  font-family: 'Poppins', sans-serif; /* Uniform font */
  padding-left: 20px; /* Align the dropdown button with other nav links */
}

.dropbtn:hover {
  color: #f0f0f0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-content div {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
  cursor: pointer;
  font-family: 'Poppins', sans-serif; /* Uniform font */
  text-align: left; /* Align text to the left */
}

.dropdown-content div:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar-content {
    justify-content: space-between;
  }

  .hamburger {
    display: block;
  }

  .navbar-links {
    display: none;
  }

  .menu-content {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 8px;
    right: 10px;
    left: 10px;
    top: 50px;
    max-height: 80vh; /* Limit height */
    overflow-y: auto; /* Make scrollable if content overflows */
  }

  .mobile-dropdown {
    padding: 10px 0;
  }

  .mobile-dropbtn {
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif; /* Uniform font */
    text-align: left;
    padding-left: 20px; /* Align with other links */
  }

  .mobile-dropbtn-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* padding-left: 20px; Align content within the button */
  }

  .dropbtn-content {
    display: inline-block;
  }

  .dropbtn-content:last-child {
    margin-right: 20px; /* Align the plus/minus symbol */
  }

  .mobile-dropbtn:hover {
    background-color: #fff;
    color: green;
  }

  .dropdown-content {
    display: none; /* Hide by default */
    position: relative;
  }

  .mobile-dropdown .dropdown-content {
    display: block; /* Show dropdown content when active */
    padding: 10px 20px; /* Align with other content */
    color: white;
    text-align: left;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .mobile-dropdown .dropdown-content div {
    text-align: left; /* Align content to the left */
    width: 100%;
    padding-left: 20px;
  }

  .mobile-dropdown .dropdown-content.show {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width:320px) and (max-width:480px) {
  .navbar-content {
    display: flex;
    align-items: center;
    padding: 10px 5px;
  }
  .logo img {
    width: 4rem;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .content-container {
        top: -10%;
    }
}
}